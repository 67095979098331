<template>
  <div class="CancelSubscription">

    <CModal
      :show.sync="isCancelSubcriptionError"
      :no-close-on-backdrop="true"
      title="Erreur lors de la souscription"
      size="lg"
      color="dark"
    >
      Une erreur s'est produite lors de l'annulation de votre abonnement. Veuillez réessayer.
      <template #footer>
        <CButton @click="isCancelSubcriptionError = false" color="dark">Fermer</CButton>
      </template>
    </CModal>

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    
    <CRow>
      <CCol sm="6">
        <h1> Annuler votre abonnement </h1>
      </CCol>
    </CRow>
    
    <CCard class="mt-3">
      <CCardBody>
        <p>
          <strong> Formule actuelle : </strong> 
          <span v-if="companyStripeProductId === stripeSoloProductId"> SOLO </span>
          <span v-else-if="companyStripeProductId === stripeSmallProductId"> SMALL </span>
          <span v-else-if="companyStripeProductId === stripeMediumProductId"> MEDIUM </span>
        </p>
        <p>
          <strong> Moyen de paiement : </strong> <span>{{defaultPaymentMethod.card.brand}} ****-{{defaultPaymentMethod.card.last4}}</span>
        </p>
        <p>
          Comme promis, Eclerk est sans engagement, vous pouvez annuler votre abonnement à tout moment. Néanmoins, nous sommes tristes de vous voir partir 😢.
          Nous conservons votre compte entreprise au cas où vous souhaiteriez revenir !
        </p>
        <p>
          N'hésitez pas à nous contacter à l'adresse <a href="mailto:contact@eclerk.io">contact@eclerk.io</a> !
        </p>
        <CButton
          :color="cancelSubscriptionButtonStyle"
          shape="pill" block class="px-4 mt-3"
          @click="cancelSubscription"
          :disabled="cancelSubscriptionInProcess">
          <CSpinner size="sm" label="update company spinner" v-if="cancelSubscriptionInProcess"></CSpinner>
          {{ cancelSubscriptionButtonText }}
        </CButton>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { stripeSoloProduct, stripeSmallProduct, stripeMediumProduct } from '@/variables/localVariables'
import { APIBossConnected } from '@/api/APIBossConnected'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiBossConnected = new APIBossConnected()

export default {
  name: 'CancelSubscription',
  components: {
    Loading,
  },
  data: function () {
    return {
      stripeSoloProductId: stripeSoloProduct,
      stripeSmallProductId: stripeSmallProduct,
      stripeMediumProductId: stripeMediumProduct,
      
      isDefaultPaymentMethodLoading: false,
      defaultPaymentMethod: {
        card: {
          brand: 'visa',
          last4: '1234'
        }
      },

      // ---- PAIEMENT --------

      cancelSubscriptionButtonText: 'Résilier votre abonnement',
      cancelSubscriptionButtonStyle: 'outline-danger',
      cancelSubscriptionInProcess: false,

      isCancelSubcriptionError: false
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    companyStripeSubscriptionId () {
      return this.$store.state.user.companyStripeSubscriptionId
    },
    companyStripeProductId () {
      return this.$store.state.user.companyStripeProductId
    },
    isLoading () {
      if (this.isDefaultPaymentMethodLoading || this.cancelSubscriptionInProcess) {
        return true
      }
      return false
    },
  },
  created () {
    this.getDefaultPaymentMethod()
  },

  methods: {
    // ------------- Stripe functions -----------
    getDefaultPaymentMethod () {
      this.isDefaultPaymentMethodLoading = true
      return apiBossConnected.getDefaultPaymentMethod(this.token)
        // If the card is declined, display an error to the user.
        .then((result) => {
          this.defaultPaymentMethod = result.data
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isDefaultPaymentMethodLoading = false 
        })
    },
    cancelSubscription() {
      this.cancelSubscriptionButtonText = 'Annulation en cours'
      this.cancelSubscriptionButtonStyle = 'secondary'
      this.cancelSubscriptionInProcess = true
      return apiBossConnected.cancelSubscription(this.token, this.companyStripeSubscriptionId)
        // If the card is declined, display an error to the user.
        .then(() => {
          this.$store.commit('SUBSCRIPTION_CANCEL')
        
          this.$store.dispatch('AUTH_LOGOUT').then(() => {
            this.$router.push('/pages/abonnement/resilie')
          })
          
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.cancelSubscriptionButtonText = 'Annuler votre abonnement'
          this.cancelSubscriptionButtonStyle = 'outline-danger'
          this.cancelSubscriptionInProcess = false
        })
    }
  }
}
</script>
